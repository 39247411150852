<script lang="ts" setup>

const route = useRoute()

const isPWA = computed(() => process.client && window.isPWA)
if (isPWA) {
  useHead({
    meta: [
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
      }
    ]
  })
}
</script>

<template>
  <main id="main" class="flex flex-col px-4">
    <Header class="border-b border-gray-100" v-if="!isPWA"/>
    <div class="flex-1">
      <slot class="w-full"/>
    </div>
    <Header
      v-if="isPWA" class="sticky bottom-0 pb-4"
      :class="{'mt-4 border-t': !['game', 'editor'].includes(route.name.toString())}"
    />
    <div v-else-if="route.name !== 'editor'" class="mt-auto">
      <div class="mt-4 py-4 -mx-4 text-sm">
        <div class="md:px-0 px-4 w-full max-w-2xl mx-auto flex gap-4">
          <p class="mr-auto">Copyright © 2023</p>
          <nuxt-link to="/about">About</nuxt-link>
          <a target="_blank" href="/sitemap-index.xml">Sitemap</a>
        </div>
      </div>
    </div>
  </main>
</template>

<style>
@supports (-webkit-touch-callout: none) {
  #main {
    margin-bottom: 20px;
  }
}
</style>
