<script setup lang="ts">
import {useUserStore} from "~/stores/user";
import {useEditor} from "~/stores/editor";
import {useClickOutSite} from "#imports";
import HeaderSearch from "~/components/HeaderSearch.vue";
const {$logout} = useNuxtApp()
const route = useRoute()
const userStore = useUserStore()
const editorStore = useEditor()

const isPWA = computed(() => process.client && window.isPWA)

const user = computed(() => {
  return userStore.isLogged ? {
    full_name: userStore.logged.first_name ? `${userStore.logged.first_name} ${userStore.logged.last_name}` : userStore.logged.username,
    username: userStore.logged.username
  } : {
    full_name: "Guess",
    username: "Guess"
  }
})
const componentRef = ref()
const showMenu = ref(false)

useClickOutSite(componentRef, () => {
  showMenu.value = false
})
</script>
<template>
  <header class="sticky top-0 z-20 bg-white -mx-4 px-4 bg-black">
    <div class="mx-auto max-w-2xl w-full font-semibold relative">
      <div class="flex justify-between items-center text-center gap-4" :class="{'text-xs': isPWA}">
        <div class="md:w-1/3 flex md:flex-none md:gap-6 gap-4 items-center">
          <nuxt-link
            class="flex gap-1 items-center md:hover:text-blue-500"
            title="Simple Pixel Art" to="/"
          >
            <img v-lazy-load  class="w-auto h-8" data-src="/favicon.svg" alt="Simple Pixel Art">
            <span class="text-[#004225] font-extrabold font-pixel -mt-0.5">art</span>
          </nuxt-link>
          <nuxt-link class="flex p-3 md:px-0 gap-1 items-center md:hover:text-blue-500" to="/editor">
            <div class="w-4 h-4 i-con-color"/>
            <span>Editor</span>
          </nuxt-link>
        </div>
        <div class="w-1/3 hidden md:flex justify-center">
          <header-search v-if="route.name != 'editor'"/>
        </div>
        <div class="md:w-1/3 flex justify-end gap-6 items-center">
          <template v-if="['editor'].includes(route.name.toString())">
            <div
              class="btn flex-col gap-1 md:flex-row p-2 md:px-0"
              @click="editorStore.toggleModal('save')"
            >
              <div class="i-con-save w-4 h-4"/>
              <span class="hidden md:block">Save</span>
            </div>
            <div
              class="btn flex-col gap-1 md:flex-row p-2 md:px-0"
              @click="editorStore.toggleModal('download')"
            >
              <div class="i-con-download w-4 h-4"/>
              <span class="hidden md:block">Download</span>
            </div>
          </template>
          <div class="relative" ref="componentRef">
            <div class="btn p-0 bg-gray-100">
              <div class="hidden md:block flex-1 p-2 pl-3">{{ user.username }}</div>
              <div class="p-2 bg-gray-50" @click="showMenu = !showMenu">
                <div class="i-con-more rotate-90 w-4 h-4"/>
              </div>
            </div>
            <div
              v-if="showMenu"
              class="absolute shadow arrow-top bg-white min-w-52 right-0 border text-sm text-left divide-y"
            >
              <div class="p-2">Hi, {{ user.username }}!</div>
              <template v-if="userStore.isLogged">
                <nuxt-link :to="`/creator/${user.username}`" class="p-2 cursor-pointer flex gap-2">
                  <div class="flex-1 flex gap-1">
                    <span>Public Profile</span>
                  </div>
                  <div class="i-con-arrow-right w-4 h-4"/>
                </nuxt-link>
                <nuxt-link to="/work" class="p-2 cursor-pointer flex gap-2">
                  <div class="flex-1 flex gap-1">
                    <span>Your works</span>
                  </div>
                  <div class="i-con-arrow-right w-4 h-4"/>
                </nuxt-link>
                <div class="p-2 cursor-pointer flex gap-2" @click="$logout">
                  <div class="flex-1 flex gap-1">
                    <span>Logout</span>
                  </div>
                  <div class="i-con-arrow-right w-4 h-4"/>
                </div>
              </template>
              <template v-else>
                <nuxt-link class="p-2 cursor-pointer flex gap-2" :to="`/hello`">
                  <div class="flex-1 flex gap-1">
                    <span>Sign in</span>
                  </div>
                  <div class="i-con-arrow-right w-4 h-4"/>
                </nuxt-link>
                <nuxt-link class="p-2 cursor-pointer flex gap-2" :to="`/hello#register`">
                  <div class="flex-1 flex gap-1">
                    <span>Signup</span>
                  </div>
                  <div class="i-con-arrow-right w-4 h-4"/>
                </nuxt-link>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style>
.arrow-top {
  top: calc(100% + 7px)
}
</style>
