<script setup lang="ts">

</script>

<template>
  <div class="flex gap-2 md:border px-3 py-1.5 md:bg-gray-50 items-center">
    <div class="w-5 h-5 i-con-magnify"/>
    <input type="text" class="hidden md:block outline-none bg-transparent" placeholder="Search...">
  </div>
</template>
